<template>
  <div className="p-5">Loading...</div>
</template>

<script setup>
import {onMounted} from 'vue';
import createApp from '@shopify/app-bridge';
import {Redirect} from '@shopify/app-bridge/actions';

onMounted(() => {
  const scopes =
    'unauthenticated_write_checkouts,unauthenticated_write_customers,unauthenticated_read_product_listings,unauthenticated_read_product_inventory,read_customers,read_orders,read_products';

  const API_KEY = 'c967f6425dcd0a0b92c8f0f160bd1dc6';

  console.log('SHOPAKJDASKDJASLKDJASLDJASLDKASLŞDKAŞSLDKALŞKDASLŞKDALŞS', shop)
  const permissionUrl = `https://${shop}/admin/oauth/authorize?client_id=${API_KEY}&scope=${scopes}&redirect_uri=https://${shop}/admin/apps/figmobi-3`;

  if (window.top == window.self) {
    const app = createApp({
      apiKey: API_KEY,
      host: host,
      shop: shop,
    });

    Redirect.create(app).dispatch(Redirect.Action.REMOTE, permissionUrl);
  } else {
    window.location.assign(permissionUrl);
  }
});

// Shopify'a Auth olmak için gidilir
//
</script>
